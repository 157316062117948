<template>
  <div>
    <div class="container my-5 pb-5">
      <div class="row text-center">
        <div class="col-md-8 mx-auto mb-3">
          <span class="h2 text-success d-block mb-3"
            >Thank you for your enquiry</span
          >
          <span class="h4 text-dark d-block mb-3"
            >We have just sent you an automatic email. Sometimes our emails can
            end up in spam folders so please check there too.</span
          >
          <span class="h4 text-dark d-block mb-3 hideMePlease">
            Meanwhile please select below
            <b
              >what you are most interested in and how we can help you with
              further information.</b
            >
          </span>
          <span class="h5 text-dark d-block mb-3 font-italic hideMePlease"
            >Click as many as apply</span
          >
        </div>
      </div>

      <form id="additionalForm">
        <div class="row mb-3 text-center">
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col">
                <span class="h4 d-block text-success font-weight-bold"
                  >Residential Health Retreats</span
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <label class="h6 text-success">UK Retreat</label>
                <div class="round">
                  <input
                    type="checkbox"
                    v-model="interestedIn"
                    value="interested_uk_retreat"
                    id="uk-retreat"
                  />
                  <label for="uk-retreat"></label>
                </div>
              </div>

              <div class="col-6">
                <label class="h6 text-success">Malta Retreat</label>
                <div class="round">
                  <input
                    type="checkbox"
                    v-model="interestedIn"
                    value="interested_malta_retreat"
                    id="malta-retreat"
                  />
                  <label for="malta-retreat"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div class="col">
                <span class="h4 d-block text-info font-weight-bold"
                  >Personalised testing for</span
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4 col-6 mb-3 mb-md-0">
                <label class="h6 text-info">Hormone Health</label>
                <div class="round">
                  <input
                    type="checkbox"
                    v-model="interestedIn"
                    value="interested_hormone"
                    id="hormone-health"
                  />
                  <label for="hormone-health"></label>
                </div>
              </div>

              <div class="col-md-4 col-6 mb-3 mb-md-0">
                <label class="h6 text-info">Digestive Health</label>
                <div class="round">
                  <input
                    type="checkbox"
                    v-model="interestedIn"
                    value="interested_digestive"
                    id="digestive-health"
                  />
                  <label for="digestive-health"></label>
                </div>
              </div>

              <div class="col-md-4 col-6 mx-auto mb-3 mb-md-0">
                <label class="h6 text-info">Genetic Health</label>
                <div class="round">
                  <input
                    type="checkbox"
                    v-model="interestedIn"
                    value="interested_genetic"
                    id="genetic-health"
                  />
                  <label for="genetic-health"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-3 text-center">
          <div class="col">
            <button
              type="button"
              class="btn btn-success btn-lg"
              @click="addDetail"
            >
              <i class="fas fa-check me-1"></i>Submit
            </button>
          </div>
        </div>
      </form>
      <div class="row text-center" id="hiddenThanks" style="display: none">
        <div class="col-md-6 mx-auto">
          <div class="card border-0 bg-success text-white shadow">
            <div class="card-body">
              <span class="h4 font-italic"
                >Thank you for your additional feedback</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      enquiry: null,
      interestedIn: [],
    };
  },
  methods: {
    addDetail() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/enquiries/" +
            this.enquiry +
            "/add-detail",
          {
            interested_in: this.interestedIn,
          }
        )
        .then(({ data }) => {
          $("#additionalForm").hide();
          $(".hideMePlease").remove();
          $("#hiddenThanks").show();
          $("html, body").animate({ scrollTop: 0 }, "fast");
        });
    },
  },
  mounted() {
    var url = new URL(window.location.href);
    var enquiryId = url.searchParams.get("enquiry");
    this.enquiry = enquiryId;
    console.log(this.enquiry);
  },
};
</script>

<style>
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 auto;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
</style>